
import Vue from 'vue'
import { KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { IBillOfMaterialItem } from '../types'
import { dispatchGetBillOfMaterials } from '../store/actions'

export default Vue.extend({
  name: 'FFBillOfMaterialDetail',
  components: { KwikMediumContainer, KwikPageToolbar },
  data () {
    return {
      bom: null as IBillOfMaterialItem | null,
      active: null as IBillOfMaterialItem | null,
      name: '',
      shop: '',
      isMake: false,
      quantity: 0,
      unit_of_measure: '',
      leadTime: 0,
      loading: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Distinta base', disabled: false }
      ]
    }
  },
  methods: {
    clearInfo () {
      this.name = ''
      this.shop = ''
      this.isMake = false
      this.quantity = 0
      this.leadTime = 0
    },
    compileInfo (bom: IBillOfMaterialItem) {
      debugger
      this.name = bom.name
      this.shop = bom.shop
      this.isMake = bom.is_make
      this.quantity = bom.unit_quantity
      this.leadTime = bom.lead_time
      this.unit_of_measure = bom.unit_of_measure
    }
  },
  async mounted () {
    this.loading = true
    const productId = +this.$router.currentRoute.params.product_id
    this.bom = await dispatchGetBillOfMaterials(this.$store, productId)
    this.loading = false
  },
  watch: {
    active: {
      handler (selected) {
        if (selected.length < 1) {
          this.clearInfo()
        } else {
          this.compileInfo(selected[0])
        }
      }
    }
  }
})
